import React from 'react'
import styled from 'styled-components'
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'
import { photos } from '../../assets/featurePhotos'

const Container = styled.div`
  margin-top: 30px;
  width: 66%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    width: 92%;
  }

  .awssld {
    /* custom styles for AwesomeSlider */
    --control-bullet-active-color: var(--site-clr-accent);
    --loader-bar-color: var(--site-clr-accent);
    --loader-bar-height: 4px;
    --organic-arrow-color: white;
    --organic-arrow-thickness: 5px;
    --organic-arrow-border-radius: 4px;
  }
`

export const MediaCarousel = () => {
  return (
    <Container>
      <AwesomeSlider>
        {photos.map((item, index) => {
          return (
            <div key={index} data-src={ item.src } />
          )
        })}
      </AwesomeSlider>
    </Container>
  )
}
