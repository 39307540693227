import React from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';

export const GigList = ({ items }) => {
  const ListItem = styled.div`
    font-family: Poppins, Arial;
    font-size: clamp(0.9rem, 1.7vw, 2rem);
    border-bottom: 1px dashed var(--site-clr-dark-accent);
    padding-top: 7px;
    padding-bottom: 7px;

    .date-style {
      color: var(--site-clr-accent);
    }
  `;
  return (
    <>
      <ListItem>&nbsp;</ListItem>
      {items.map((item, index) => {
        return (
          <ListItem>
            <div key={index}>
              <span class="date-style">
                <Moment format="DD/MM/YYYY">{item.start_time}</Moment>
              </span>{' '}
              - {item.place.name}
            </div>
          </ListItem>
        );
      })}
    </>
  );
};
