import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

library.add(faBars)

export const MenuIcon = (props) => {

  const primaryColor = props.primaryColor || '#229897';
  const secondaryColor = props.secondaryColor || '#DCDCDC';

  const Container = styled.button`
    margin: 0 auto;
    text-align: center;
    all: unset;
  `

  const IconOutterWrapper = styled.div`
    cursor: pointer;
    display: block;
    width: ${props.size}px; 
    height: ${props.size}px;
    background-color: ${primaryColor};
    border-radius: 50%;
    transition: ease background-color 500ms;
    border: 2px solid ${primaryColor};
    color: ${secondaryColor};
    text-align: center;
    font-size: ${props.size / 2.36}pt;

    &:hover, &:active {
      color: ${primaryColor};
      background-color: ${secondaryColor};
    }
  `

  const IconInnerWrapper = styled.div`
    padding-top: ${props.size / 4.5}px;
  `

  return (
    <Container onClick={props.onClick}>
      <IconOutterWrapper>
        <IconInnerWrapper>
          <FontAwesomeIcon icon={faBars} fixedWidth />
        </IconInnerWrapper>
      </IconOutterWrapper>
    </Container>
  )
}
