import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: white;
  text-align: left;
  font-size: 11.5pt;

  .text-accent {
    color: var(--site-clr-accent);
  }

  .text-email a,
  a:hover,
  a:active,
  a:link {
    color: var(--site-clr-accent);
    text-decoration: none;
  }

  .text-email a:hover {
    color: var(--site-clr-accent);
    text-decoration: underline;
  }
`;

export const AboutUsText = () => {
  return (
    <Container>
      Fourshore, <span className="text-accent">4 mates</span> that grew up on the{' '}
      <span className="text-accent">foreshores</span> of the sunny gold coast reuniting and forming during these trying
      COVID times to bring you some rocking tunes and good memories.
      <br />
      <br />
      We pride ourselves on pumping out tunes for all generations. Our covers include songs from iconic artists such as
      Nivana, Paul Kelly, Pearl Jam, The Weekend, The Angels, Icehouse and the Foo Fighters just to name a few.
      <br />
      <br />
      The band consists of <span className="text-accent">Col</span> (Lead Vocals/Guitar),{' '}
      <span className="text-accent">Joel</span> (Lead Guitar), <span className="text-accent">Justin</span>{' '}
      (Drums/Vocals) and <span className="text-accent">Sam</span> (Bass/Vocals).
      <br />
      <br />
      For booking enquiries send an email to{' '}
      <p className="text-email">
        <a href="mailto:fourshoremanagement@gmail.com">fourshoremanagement@gmail.com</a>
      </p>
    </Container>
  );
};
