import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from 'gatsby'

const Container = styled.div`
  margin: 10px 0px;
  margin-top: 15vh;

  .push-right {
    text-align: right;
  }

  .push-middle {
    text-align: center;
  }
`

export const Jellyfish = () => {

  const pageData = useStaticQuery(graphql`
    query {
      jellyfishFront: file(name: {eq: "jelly-fish-front"}) {
        childImageSharp {
          gatsbyImageData(
            width: 358,
            layout: CONSTRAINED, 
            placeholder: DOMINANT_COLOR,
            quality: 100
          )
        }
      },
      jellyfishMiddle: file(name: {eq: "jelly-fish-middle"}) {
        childImageSharp {
          gatsbyImageData(
            width: 303,
            layout: CONSTRAINED, 
            placeholder: DOMINANT_COLOR,
            quality: 100
          )
        }
      },
      jellyfishBack: file(name: {eq: "jelly-fish-back"}) {
        childImageSharp {
          gatsbyImageData(
            width: 158,
            layout: CONSTRAINED, 
            placeholder: DOMINANT_COLOR,
            quality: 100
          )
        }
      }
    }
  `)

  const rightJellyfish = getImage(pageData.jellyfishFront);
  const backJellyfish = getImage(pageData.jellyfishBack);
  const leftJellyfish = getImage(pageData.jellyfishMiddle);

  return (
    <Container>
      <div class="grid">
        <div class="col-1">&nbsp;</div>
        <div class="col-3">
          <GatsbyImage image={leftJellyfish} />
        </div>
        <div class="col-2 push-middle">
          <GatsbyImage image={backJellyfish} />
        </div>
        <div class="col-4 push-right">
          <GatsbyImage image={rightJellyfish} />
        </div>
        <div class="col-1">&nbsp;</div>
      </div>
    </Container>
  )
}
