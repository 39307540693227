import React from 'react'
import styled from 'styled-components'

export const Header = (props) => {

  const HeroContainer = styled.header`
    width: 100%;
    height: 0;
    background-image: url("/images/fourshore-hero.jpg");
    background-repeat: repeat-x;
    background-size: contain;
    background-position: center;
    position: relative;
    padding-top: 61.67%;  // 1258 / 2040 * 100 = 61.67%
  `

  const HeroBody = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
  `
  return (
    <HeroContainer>
      <HeroBody>
        {props.children}
      </HeroBody>
    </HeroContainer>
  )
}
