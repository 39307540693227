import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from 'react'
import styled from 'styled-components'
import { FacebookIcon } from '../FacebookIcon'
import { InstagramIcon } from '../InstagramIcon'

const Container = styled.footer`
  margin-top: auto;
  color: var(--site-clr-light);

  .footer-triangle {
    width: 100%;
    height: clamp(30px, 0.8vh, 100px);
    background-color: var(--site-clr-footer);
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
  }

  .footer-body {
    background-color: var(--site-clr-footer);
    width: 100%;
    padding-top: calc(20px + 1vh);
    font-family: Arial;
  }

  .menu-items {
    display: grid;
    grid-template-columns: repeat(4, auto);
    height: 100%;
    padding: 0;
    margin: 0;
    align-items: center;
    list-style: none;
    font-size: calc(5pt + 1vw);
    font-family: Roboto, Arial !important;
    font-weight: bold;
    text-align: right;
    z-index: 20;
    padding-right: 0.25vw;
  }

  .menu-items a {
    padding: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-left: 10px;
    margin-right: 10px;
  }

  .menu-items a:hover, a:active {
    cursor: pointer;
    background: rgba(34,152, 151, 0.75);
    /* color: var(--site-clr-light); */
    color: var(--site-clr-dark);
    border-radius: 5px;
  }

  .footer-body-divider-line {
    margin-top: 1vw;
    margin-bottom: 0.5vw;
    border-top: 1px solid var(--site-clr-light);
    line-height: 0;
  }

  .footer-gutter {
    padding-top: 0;
    padding-bottom: 3vh;
  }

  .footer-bottom-row {
    display: grid;
    grid-template-columns: auto 3fr;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .follow-us {
    font-size: clamp(3pt, 3pt + 1vw, 16pt);
    margin-right: 2vw;
  }

  .trademark {
    font-size: clamp(7pt, 2.5pt + 1vw, 12pt);
    text-align: right;
    padding-right: 0;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
`

export const Footer = () => {

  const pageData = useStaticQuery(graphql`
    query {
      logo: file(name: {eq: "fourshore-logo-footer"}) {
        childImageSharp {
          gatsbyImageData(
            width: 250,
            layout: CONSTRAINED, 
            placeholder: DOMINANT_COLOR,
            quality: 100
          )
        }
      }
    }
  `)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.history.replaceState(null, null, '');
  }

  const scrollToSection = (event) => {
    event.preventDefault();

    const linkName = event.target.innerHTML.toLowerCase();

    if ((/^home$/i).test(linkName)) {
      scrollToTop();
    } else {
      // programmatically scrolling avoids ugly '#' in url
      const id = "nav-" + linkName;
      const element = document.querySelector("#" + id);
      element.scrollIntoView();
    }
  }

  const menuItems = ['GIGS', 'ABOUT', 'MEDIA'];
  const fourshoreLogo = getImage(pageData.logo);

  return (
    <Container id="footer-container">
      <div class="footer-triangle">&nbsp;</div>
      <div class="footer-body">
        <div>
          <div class="fixed-width-container">
            <div class="grid grid-bleed">
              <div class="col-1">&nbsp;</div>
              <div class="col-3">
                <GatsbyImage image={fourshoreLogo} alt="fourshore logo" />
              </div>
              <div class="col-7 col-bleed">
                <ul class="menu-items">
                  {menuItems.map((item, index) => {
                    return <li key={index}>
                      <a href="#" class="reset-anchor" onClick={scrollToSection}>{item}</a>
                    </li>
                  })}
                </ul>
              </div>
              <div class="col-1">&nbsp;</div>
            </div>
            <div class="grid grid-bleed">
              <div class="col-1">&nbsp;</div>
              <div class="col-10 footer-body-divider-line">&nbsp;</div>
              <div class="col-1">&nbsp;</div>
            </div>
            <div class="grid grid-bleed footer-gutter">
              <div class="col-1">&nbsp;</div>
              <div class="col-5">
                <div class="footer-bottom-row">
                  <span class="follow-us">FOLLOW US ON</span>
                  <span class="follow-us-icons">
                    <FacebookIcon size={22} secondaryColor={'#192938'} />
                    &nbsp;&nbsp;
                    <InstagramIcon size={22} secondaryColor={'#192938'} />
                  </span>
                </div>
              </div>
              <div class="col-5 trademark">Copyright {new Date().getFullYear()}. All rights reserved.</div>
              <div class="col-1">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
