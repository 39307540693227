import React from 'react'
import styled from 'styled-components'
import { ModalContents, ModalOpenButton, ModalProvider } from '../components/Modal'
import { FacebookIcon } from './FacebookIcon'
import { InstagramIcon } from './InstagramIcon'
import { MenuIcon } from './MenuIcon'
import ModalMenu from './ModalMenu'

export const Navbar = (props) => {

  const Navigation = styled.div`

    .nav-bar {
      display: flex;
    }

    .menu-items {
      display: grid;
      grid-template-columns: repeat(5, auto);
      grid-gap: 10px;
      list-style: none;
      font-size: calc(7pt + 1vw);
      font-family: Roboto, Arial;
      font-weight: bold;
      color: var(--site-clr-light);
      text-align: center;
      justify-content: left;
    }

    .menu-items a {
      padding: 5px;
      padding-left: 35px;
      padding-right: 35px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .menu-items a:hover, a:active {
      cursor: pointer;
      background: rgba(34,152, 151, 0.75);
      color: var(--site-clr-dark);
      border-radius: 5px;
    }

    .menu-items .divider {
      cursor: none;
      width: 2px;
      border-left: 2px solid var(--site-clr-light);
    }

    .nav-gap {
      z-index: -1;
    }

    .social-icons {
      display: flex;
      justify-content: flex-end;

      > * {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .nav-bar-small {
      display: none;
    }

    @media screen and (max-width: 768px) {
      .nav-bar {
        display: none;
      }

      .nav-bar-small {
        display: block;
        padding: 15px;
        padding-right: 2rem;
        text-align: right;
      }
    }
  `
  const menuItems = ['GIGS', 'ABOUT', 'MEDIA'];

  const scrollToSection = (event) => {
    event.preventDefault();
    // programmatically scrolling avoids ugly '#' in url
    const id = "nav-" + event.target.innerHTML.toLowerCase();
    const element = document.querySelector("#" + id);
    element.scrollIntoView();
  }

  return (
    <Navigation>
      <div class="grid grid-bleed nav-bar-small">
        <div class="col-12">
          <ModalProvider>
            <ModalOpenButton>
              <MenuIcon size={42} secondaryColor={'#192938'} />
            </ModalOpenButton>
            <ModalContents>
              <ModalMenu />
            </ModalContents>
          </ModalProvider>
        </div>
      </div>
      <div class="grid grid-bleed nav-bar">
        <div class="col-1">&nbsp;</div>
        <div class="col-5 align-content-center">
          <ul class="menu-items">
            {menuItems.map((item, index) => {
              return <li key={index} class="btn">
                <a class="reset-anchor" onClick={scrollToSection}>{item}</a>
              </li>
            })}
          </ul>
        </div>
        <div class="col-2 nav-gap">&nbsp;</div>
        <div class="col-3 social-icons">
          <InstagramIcon size={48} secondaryColor={'#192938'} />
          <FacebookIcon size={48} secondaryColor={'#192938'} />
        </div>
        <div class="col-1">&nbsp;</div>
      </div>
    </Navigation>
  )
}
