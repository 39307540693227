import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import FacebookEvents from '../assets/data/events.json'
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import { Navbar } from '../components/Navbar'
// import { ScrollToTop } from '../components/ScrollToTop'
import { AboutUs } from '../components/sections/AboutUs'
import { Footer } from '../components/sections/Footer'
import { Gigs } from '../components/sections/Gigs'
import { Header } from '../components/sections/Header'
import { Jellyfish } from '../components/sections/Jellyfish'
import { MediaCarousel } from '../components/sections/MediaCarousel'
import { Title } from '../components/Title'

const HomePage = () => {

  const PageStyle = styled.div`
    color: white;
    font-size: 1.2rem;
    width: 100%;

    #nav-about {
      margin-top: clamp(20px, 10vw, 75px);
    }

    @media screen and (max-width: 768px) {
      .about-us-title {
        display: none;
      }
    }
  `

  let gigsTitle = 'Upcoming gigs';

  const filterGigs = (events) => {
    let partitions = _.partition(events, (gig) => {
      var gigDate = Date.parse(gig.end_time);
      return gigDate > new Date();
    });

    let [futureGigs, pastGigs] = partitions;

    if (futureGigs.length > 0) {
      gigsTitle = 'Upcoming gigs';
      return _.sortBy(futureGigs, ['start_time']);
    } else {
      gigsTitle = 'Past gigs';
      return _.reverse(_.sortBy(pastGigs, ['start_time']));
    }
  }

  const fourshoreGigs = filterGigs(FacebookEvents);

  return (
    <Layout>
      <Meta title='Fourshore' />
      <PageStyle>
        <Header>
          <Navbar />
        </Header>
        <div class="fixed-width-container">
          <div id="nav-gigs">
            <Title text={gigsTitle} />
          </div>
          <div>
            <Gigs events={fourshoreGigs} />
          </div>
          <div id="nav-about">
            <div class="about-us-title">
              <Title text={"About Us"} />
            </div>
          </div>
          <div>
            <AboutUs />
          </div>
          <div id="nav-media">
            <Title text={"Media"} />
          </div>
          <div>
            <MediaCarousel />
          </div>
          <div>
            <Jellyfish />
          </div>
          <Footer />
        </div>
      </PageStyle>
      {/* <ScrollToTop /> */}
    </Layout>
  )
}

export default HomePage
