export const photos = [
  {
    src: "/images/gallery/003.jpg"
  },
  {
    src: "/images/gallery/005.jpg"
  },
  {
    src: "/images/gallery/002.jpg"
  },
  {
    src: "/images/gallery/001.jpg"
  },
  {
    src: "/images/gallery/004.jpg"
  }
];
