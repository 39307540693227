import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { AboutUsText } from '../AboutUsText'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: 10vh 2vw;
  margin-top: clamp(20px, 8vh, 75px);
  color: white;

  .about-us-text {
    width: 100%;
    font-family: Arial;
    font-size: 1rem;
    padding-left: 1.5rem;
  }

  .the-boys {
    text-align: right;
    vertical-align: center;
  }

  @media screen and (max-width: 768px) {
    .the-boys {
      text-align: center;
    }
    .the-logo {
      text-align: center;
    }
    .about-us-text {
      text-align: center;
    }
  }
`

export const AboutUs = () => {

  const pageData = useStaticQuery(graphql`
    query {
      theBoys: file(name: {eq: "the-boys-min"}) {
        childImageSharp {
          gatsbyImageData(
            width: 500,
            layout: CONSTRAINED, 
            placeholder: DOMINANT_COLOR,
            quality: 100
          )
        }
      },
      logo: file(name: {eq: "fourshore-logo-about-us"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            layout: CONSTRAINED,
            placeholder: DOMINANT_COLOR,
            quality: 100
          )
        }
      }
    }
  `)

  const logo = getImage(pageData.logo);
  const theBoys = getImage(pageData.theBoys);

  return (
    <Container>
      <div class="grid hidden-xxs hidden-xs">
        <div class="col-6 the-boys">
          <div class="grid">
            <div class="col-1">&nbsp;</div>
            <div class="col-11">
              <GatsbyImage image={theBoys} />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="grid direction-column">
            <div class="col-6">
              <GatsbyImage image={logo} />
            </div>
          </div>
          <div class="col-6 about-us-text">
            <div class="grid">
              <div class="col-8">
                <AboutUsText />
              </div>
              <div class="col-4">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid hidden-sm hidden-md hidden-lg hidden-xlg direction-column">
        <div class="col-12 the-logo">
          <GatsbyImage image={logo} />
        </div>
        <div class="col-12 about-us-text">
          <AboutUsText />
        </div>
        <div class="col-12 the-boys">
          <GatsbyImage image={theBoys} />
        </div>
      </div>
    </Container>
  )
}
