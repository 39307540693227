import React from 'react'
import styled from 'styled-components'
import { GigCard } from '../GigCard'
import { GigList } from '../GigList'

const Container = styled.div`
`

const GigListContainer = styled.div`
  position: relative;
  width: 100%;
  color: white;
  text-align: center;
  margin-top: 3vh;
`

export const Gigs = ({ events }) => {
  function renderGigs(events) {
    if (events.length > 3) {
      return (
        <div class="col-12">
          <GigList items={events} />
        </div>
      )
    } else {
      return (
        <div class="col-12">
          {events.map((item, index) => {
            return <GigCard key={index} item={item} width={375} />
          })}
        </div>
      )
    }
  }
  return (
    <Container>
      <div class="col-12 col-bleed">
        <GigListContainer>
          <div class="grid">
            {renderGigs(events)}
          </div>
        </GigListContainer>
      </div>
    </Container>
  )
}
